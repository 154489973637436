import AsyncSearchInput from 'components/common/asyncSearchInput'
import CdnSvg from 'components/common/cdnSvg'
import { PaginationData } from 'components/common/hooks/useApi'
import useFetch from 'components/common/hooks/useFetch'
import useQueryParamState from 'components/common/hooks/useQueryParamsState'
import SmartTable from 'components/common/tables/smartTable'
import UserLink from 'components/common/userLink'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import BaseLayout from 'components/layout/baseLayout'
import TicketListPageSidebar from 'pages/admin/ticketing/ticketListPageSidebar'
import TicketStatusLabel from 'components/ticketing/ticketStatusLabel'
import TicketStatusSelect from 'components/ticketing/ticketStatusSelect'
import { TicketStatusEnum, TicketType } from 'types/ticketing/ticket'
import React, { useState } from 'react'
import API from 'services/api'
import { UserType } from 'types/user'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import DateCell from 'components/common/tables/dateCell'

const I18N = i18nPath('views.admin.ticketing')

const columns = [
  {
    header: I18N('table.headers.number'),
    style: { width: '70px' },
    col: 'col-number',
    accessor: (ticket: TicketType) => `#${ticket.number}`,
  },
  {
    header: I18N('table.headers.status'),
    style: { width: '160px' },
    col: 'col-status',
    accessor: (ticket: TicketType) => <TicketStatusLabel status={ticket.status} />,
  },
  {
    header: I18N('table.headers.subject'),
    col: 'col-title',
    accessor: (ticket: TicketType) => (
      <>
        {ticket.title}
      </>
    ),
  },
  {
    header: I18N('table.headers.requester'),
    col: 'col-requester',
    style: { width: '200px' },
    accessor: (ticket: TicketType) => <UserLink user={ticket.user} />,
  },
  {
    header: I18N('table.headers.requested_at'),
    col: 'col-requested-at',
    style: { width: '200px' },
    accessor: (ticket: TicketType) => <DateCell date={ticket.createdAt} />,
  },
  {
    header: I18N('table.headers.assigned_to'),
    col: 'col-assigned-to',
    style: { width: '200px' },
    accessor: (ticket: TicketType) => (ticket.assignee ? <UserLink user={ticket.assignee} /> : null),
  },
]


const TicketListPageContent = ({
  tickets,
  toggleSidebarComponent,
  isSidebarOpen,
  paginationData,
  fetchTickets,
}: {
  isLoading: boolean
  tickets: TicketType[]
  toggleSidebarComponent?: React.ReactNode
  isSidebarOpen?: boolean
  paginationData: PaginationData
  fetchTickets: ({ page }: { page: number }) => void
}) => {
  const [status, setStatus] = useQueryParamState<TicketStatusEnum | undefined>({
    param: 'status',
    initialValue: undefined,
  })

  const [searchQuery, setSearchQuery] = useState('')
  const [selectedEmployee, setSelectedEmployee] = useState<UserType | undefined>(undefined)

  return (
    <div className='TicketListPage'>
      <header className='TicketListPage__header d-flex justify-content-between px-4 py-3'>
        <div className='d-flex align-items-center'>
          {!isSidebarOpen && <div className='ml-n3 mr-2'>{toggleSidebarComponent}</div>}

          <AsyncSearchInput
            placeholder={I18N('search_by_title')}
            className='w-100'
            onKeyUp={setSearchQuery}
            icon={<CdnSvg src='/images/searchIcon.svg' />}
            minCharsToSearch={3}
            inputGroupClassName='flex-grow-1 mr-2'
          />
        </div>

        <div className='d-flex align-items-center'>
          <EmployeeSearch
            onChange={setSelectedEmployee}
            selectedEmployee={selectedEmployee}
            className='mr-3'
          />

          <TicketStatusSelect value={status} onChange={setStatus} />
        </div>
      </header>

      <main className='AdminContent m-0'>
        <SmartTable
          columns={columns}
          data={tickets}
          className='white-bg-table'
          showPagination
          page={paginationData.page}
          pages={paginationData.totalPages}
          onPaginationClick={data => fetchTickets(data)}
          onSortChange={() => {}}
          sortKey='created_at'
          sortedAsc={false}
        />
      </main>
    </div>
  )
}

const TicketListPage = () => {
  const {
    isLoading, data: tickets, paginationData, callApi: fetchTickets,
  } = useFetch<TicketType[]>(API.admin.ticketing.tickets.fetchAll)

  return (
    <BaseLayout sidebar={<TicketListPageSidebar />}>
      <TicketListPageContent
        isLoading={isLoading}
        tickets={tickets ?? []}
        paginationData={paginationData}
        fetchTickets={fetchTickets}
      />
    </BaseLayout>
  )
}

export default TicketListPage
